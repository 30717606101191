import { injectable } from 'inversify';
import { action, makeAutoObservable, observable } from 'mobx';
import type { Action, History, Location } from 'utils/history';
import { IHistoryStackObserver } from './types';

@injectable()
class HistoryStack implements IHistoryStackObserver {
  @observable
  history: Location[];

  constructor() {
    this.history = [];
    makeAutoObservable(this);
  }

  subscribe(history: History) {
    history.listen(this._subscribtion.bind(this));
  }

  @action
  private _subscribtion({ location, action }: { location: Location; action: Action }) {
    switch (action) {
      case 'POP':
        this.history.splice(0, this.history.length - 1);
        break;
      case 'PUSH':
        this.history.push(location);
        break;
      case 'REPLACE':
        this.history.splice(0, this.history.length - 1);
        this.history.push(location);
        break;
    }
  }
}

export default HistoryStack;
