import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import Fallback from 'components/Fallback';
import { ProtoObject } from 'types/common';

const fallbackCallback: React.ComponentProps<typeof ErrorBoundary>['fallback'] = ({
  error,
}) => {
  return <Fallback error={error as Error} />;
};

const withUserlessErrorBoundary =
  <P extends ProtoObject = ProtoObject>(Component: React.ComponentType<P>) =>
  (props: P) => {
    return (
      <ErrorBoundary fallback={fallbackCallback}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

export default withUserlessErrorBoundary;
