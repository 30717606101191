import React, { useMemo } from 'react';
import { IconProps } from '@10d/tend-ui/icons/types';
import { ITestable } from 'types/common';
import * as uiIcons from './Icons';

const icons = {
  ...uiIcons,
};

export interface IIcon extends IconProps, ITestable {
  type: keyof typeof icons;
}

const Icon: React.FC<React.PropsWithChildren<IIcon>> = ({
  type,
  testId,
  onClick,
  style,
  ...props
}) => {
  const Component = icons[type] as React.FC<React.PropsWithChildren<IconProps>>;

  const iconStyle = useMemo(
    () => ({
      ...style,
      cursor: onClick ? 'pointer' : 'inherit',
    }),
    [onClick, style],
  );

  return (
    <Component onClick={onClick} style={iconStyle} data-testid={testId} {...props} />
  );
};

export default Icon;
