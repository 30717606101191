import 'reflect-metadata';
import 'utils/wdyr';
import 'core-js/actual/object/has-own';
import React from 'react';
import { createRoot } from 'react-dom/client';
import initSentry from 'utils/sentry';
import { replaceFavicon } from 'utils/favicon';
import { getStandFromEnv } from 'utils/stand';
import 'moment/locale/ru';
import App from 'App';

import 'style.css';

const init = () => {
  const stand = getStandFromEnv();
  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  initSentry(stand);
  replaceFavicon(stand);
  root.render(<App />);
};

init();
