import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { JWTPayload, decodeJwt } from 'jose';
import { ONE_SECOND } from '@webcommon/constants';
import { getMoment } from 'helpers/time';
import { FullScreenSpin } from 'components/Spin';
import { authType, currentProvider } from './helpers';
import { IAuthProvider } from './type';
import { Envs } from './common/enums';

interface IAuthContext {
  token: string | undefined;
  claim?: JWTPayload;
  provider: IAuthProvider;
}

export const AuthData = React.createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [now, setNow] = useState(getMoment());
  const [token, setToken] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(true);

  const claim = useMemo(() => (token ? decodeJwt(token as string) : undefined), [token]);

  const authorize = useCallback(async () => {
    try {
      await currentProvider.init();
      setToken(currentProvider.getToken());
      if (authType === Envs.SAAS) {
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const interval = setTimeout(() => {
      setNow(getMoment());
    }, ONE_SECOND);

    return () => clearTimeout(interval);
  }, [now]);

  useEffect(() => {
    authorize();
  }, [authorize]);

  useEffect(() => {
    const cb = () => {
      const isVisible = document.visibilityState === 'visible';

      if (isVisible) {
        authorize();
      }
    };

    document.addEventListener('visibilitychange', cb);

    return () => {
      document.removeEventListener('visibilitychange', cb);
    };
  }, [authorize]);

  /*
  useEffect(() => {
    const isExpired = claim?.exp && now.isAfter(getMoment.unix(claim.exp));

    if (isExpired) {
      currentProvider.updateToken(authorize);
      setToken(currentProvider.getToken());
    }
  }, [authorize, claim?.exp, now]);
  */

  const value = useMemo(
    (): IAuthContext => ({
      token,
      claim: token ? claim : undefined,
      provider: currentProvider,
    }),
    [claim, token],
  );

  if (isLoading && authType === Envs.SAAS) return <FullScreenSpin />;

  return <AuthData.Provider value={value}>{children}</AuthData.Provider>;
};

export const useAuth = () => useContext(AuthData);
