import React from 'react';
import { Status } from '@10d/tend-ui/components/Status';
import { usePush } from 'hooks/routes';
import { Wrapper } from './styled';

const description =
  'Возникла непредвиденная ошибка. Мы уже получили автоматический отчет со всеми подробностями и работаем над ее устранением.';

interface IFallbackProps {
  error: Error;
}

const Fallback: React.FC<React.PropsWithChildren<IFallbackProps>> = ({ error }) => {
  const goToMain = usePush('/');

  let currentDesctiption = description;
  if (process?.env?.NODE_ENV === 'test') {
    currentDesctiption = error.message;
  }

  return (
    <Wrapper>
      <Status
        status={500}
        onClick={goToMain}
        title='Ошибочка вышла...'
        description={currentDesctiption}
      />
    </Wrapper>
  );
};

export default Fallback;
