import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useProfileStorage } from 'hooks/profile';
import { getInitialRoute } from './helpers/initialRoute';

const RedirectByRoles: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { profile } = useProfileStorage();

  const route = useMemo(() => getInitialRoute(profile), [profile]);

  return <Navigate to={route} />;
};

export default RedirectByRoles;
