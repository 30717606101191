import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import Fallback from 'components/Fallback';
import { useProfileStorage } from 'hooks/profile';
import { getLocalStorage, setLocalStorage } from '../helpers';

const HAS_FORCED_REFRESH_KEY = 'hasForcedRefresh';

const withErrorBoundary =
  (Component: React.FC<React.PropsWithChildren<unknown>>) => () => {
    const { profile, isAnonymous } = useProfileStorage();

    return (
      <ErrorBoundary
        fallback={({ error }) => {
          const hasChunkLoadError = /ChunkLoadError/.test((error as Error)?.name);
          const hasForcedRefresh = getLocalStorage(HAS_FORCED_REFRESH_KEY) || false;

          if (hasChunkLoadError) {
            if (!hasForcedRefresh) {
              setLocalStorage(HAS_FORCED_REFRESH_KEY, 'true');
              window.location.reload();
            }
          } else {
            setLocalStorage(HAS_FORCED_REFRESH_KEY, 'false');
          }

          return <Fallback error={error as Error} />;
        }}
        beforeCapture={scope => {
          !isAnonymous &&
            scope.setUser({ username: profile.username, email: profile.email });
          scope.setTag('username', isAnonymous ? 'Anonymous' : profile.username);
        }}
      >
        <Component />
      </ErrorBoundary>
    );
  };

export default withErrorBoundary;
