import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type useReturn<T = string | undefined> = (pathname?: T) => void;

export const usePush = <T>(path?: string): useReturn<T> => {
  const navigate = useNavigate();

  return useCallback(
    (pathname: any) => {
      const route = (typeof pathname === 'string' && pathname) || path;

      route && navigate(route);
    },
    [navigate, path],
  );
};

export const useReplace = (path: string): useReturn => {
  const navigate = useNavigate();

  return useCallback((pathname?: string) => navigate(pathname || path), [navigate, path]);
};

export const useGoBack = (): useReturn => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const backUrl = String(pathname.split('/').slice(0, -1).join('/'));

  return useCallback(
    () => (navigate.length > 2 ? navigate(-1) : navigate(backUrl)),
    [backUrl, navigate],
  );
};

export const useGoBackToSection = (): useReturn => {
  const navigate = useNavigate();
  const section = useGetPathnameSection();
  const backUrl = `/${section}`;

  return useCallback(
    () => (history.length > 2 ? navigate(-1) : navigate(backUrl)),
    [backUrl, navigate],
  );
};

export const useGetPathnameSection = (): string => {
  const { pathname } = useLocation();

  if (pathname === '' || pathname === '/') {
    return '/';
  }

  return pathname.split('/').filter(Boolean).slice(0, 1).join();
};
