import { useEffect } from 'react';
import { useHistoryStackObserver } from 'models/HistoryStack/hooks';
import { history } from 'utils/history';

const useInitHistoryStack = () => {
  const stack = useHistoryStackObserver();

  useEffect(() => {
    stack.subscribe(history);
  }, [stack]);
};

export default useInitHistoryStack;
