import React from 'react';
import {
  unstable_HistoryRouter as HistoryRouter,
  HistoryRouterProps,
} from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { queryClient } from 'api/queryClient';
import { WithDI } from 'Root';
import store from 'store';
import DIProvider from 'core/di/Container';
import { AuthProvider } from 'context/Auth';
import { AppContextProvider } from 'context/App/AppContext';
import withUserlessErrorBoundary from 'hocs/withUserlessErrorBoundary';
import { history } from 'utils/history';

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <HistoryRouter history={history as unknown as HistoryRouterProps['history']}>
          <DIProvider>
            <AuthProvider>
              <AppContextProvider>
                <WithDI />
              </AppContextProvider>
            </AuthProvider>
          </DIProvider>
        </HistoryRouter>
      </Provider>
    </QueryClientProvider>
  );
};

export default withUserlessErrorBoundary(App);
