import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  ISamoletChat,
  ISamoletChatOptions,
} from '@devdept/samolet-chat/dist/types/samoletChat';
import { useModal } from 'hooks/modal';
import { getStandFromEnv } from 'utils/stand';
import { CHAT_KINDS_PROJECT } from 'constants/chat';
import { currentProvider } from 'context/Auth/helpers';
import { FullScreenSpin } from 'components/Spin';

interface IChatProps extends ISamoletChat {
  triggerLoading: () => void;
}

export const ChatData = React.createContext<IChatProps>({} as IChatProps);

export const ChatProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { show, hide, visible } = useModal();
  const [options, setOptions] = useState<ISamoletChatOptions>({});
  const [countMessages, updateCountMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const stand = getStandFromEnv();

  const onOpenChat = useCallback(
    (options?: ISamoletChatOptions) => {
      if (options) setOptions(options);
      show();
    },
    [show],
  );

  const onCloseChat = useCallback(() => {
    setOptions({});
    hide();
  }, [hide]);

  const triggerLoading = useCallback(() => {
    setIsLoading(!isLoading);
  }, [isLoading]);

  const values = useMemo(
    () => ({
      jwt: currentProvider.getToken(),
      kindsProject: CHAT_KINDS_PROJECT,
      stand: stand as ISamoletChat['stand'],
      options,
      visible,
      onOpenChat,
      onCloseChat,
      countMessages,
      triggerLoading,
      updateCountMessages,
    }),
    [countMessages, onCloseChat, onOpenChat, options, stand, triggerLoading, visible],
  );

  return (
    <ChatData.Provider value={values}>
      {isLoading && <FullScreenSpin $transparentBg />}
      {children}
    </ChatData.Provider>
  );
};

export const useChat = () => useContext(ChatData);
