import { DEPARTMENT, NPermissions, PERMISSION, TPermissions } from 'types/user';
import {
  ROUTE_ACCREDITATION,
  ROUTE_COMPANY,
  ROUTE_CONTRACT,
  ROUTE_CONTRACTOR,
  ROUTE_DOCUMENTATION,
  ROUTE_REVIEWS,
  ROUTE_SUPPORT,
  ROUTE_TENDERS,
} from 'constants/routes';
import { IIcon } from 'components/Icon';

export enum AsideMenuItemsKeys {
  CONTRACTOR = 'CONTRACTOR',
  ACCREDITATION = 'ACCREDITATION',
  REVIEW = 'REVIEW',
  COMPANY = 'COMPANY',
  TENDERS = 'TENDERS',
  CONTRACT = 'CONTRACT',
  DOCUMENTATION = 'DOCUMENTATION',
  SUPPORT = 'SUPPORT',
}

export const ICON_SIZE = 20;

export type AsideMenuRecord<T> = Record<AsideMenuItemsKeys, T>;

export enum AsideMenuItemsRules {
  Employee = 'Employee', // Сотрудник
  NotEmployee = 'NotEmployee', // Контрагент
  NotDemo = 'NotDemo',
  All = 'All',
}

export interface IAsideMenuCreator {
  employee?: boolean;
  notEmployee?: boolean;
  isAnonymous?: boolean;
  permissions?: TPermissions[];
  hideContract?: boolean;
  onClick: (key: AsideMenuItemsKeys) => void;
}

export const asideMenuItemsIcons: AsideMenuRecord<IIcon['type']> = {
  [AsideMenuItemsKeys.CONTRACTOR]: 'Group',
  [AsideMenuItemsKeys.ACCREDITATION]: 'ShieldDone',
  [AsideMenuItemsKeys.REVIEW]: 'DoneCircle',
  [AsideMenuItemsKeys.COMPANY]: 'Bank',
  [AsideMenuItemsKeys.TENDERS]: 'Ruble',
  [AsideMenuItemsKeys.CONTRACT]: 'FileDone',
  [AsideMenuItemsKeys.DOCUMENTATION]: 'FileText',
  [AsideMenuItemsKeys.SUPPORT]: 'HeadphoneSupport',
};

export const asideMenuItemsRoutes: AsideMenuRecord<string> = {
  [AsideMenuItemsKeys.CONTRACTOR]: ROUTE_CONTRACTOR,
  [AsideMenuItemsKeys.ACCREDITATION]: ROUTE_ACCREDITATION,
  [AsideMenuItemsKeys.REVIEW]: ROUTE_REVIEWS,
  [AsideMenuItemsKeys.COMPANY]: ROUTE_COMPANY,
  [AsideMenuItemsKeys.TENDERS]: ROUTE_TENDERS,
  [AsideMenuItemsKeys.CONTRACT]: ROUTE_CONTRACT,
  [AsideMenuItemsKeys.DOCUMENTATION]: ROUTE_DOCUMENTATION,
  [AsideMenuItemsKeys.SUPPORT]: ROUTE_SUPPORT,
};

export const asideMenuItemsValues: AsideMenuRecord<string> = {
  [AsideMenuItemsKeys.CONTRACTOR]: 'Компании',
  [AsideMenuItemsKeys.ACCREDITATION]: 'Аккредитация',
  [AsideMenuItemsKeys.REVIEW]: 'Проверки',
  [AsideMenuItemsKeys.COMPANY]: 'Моя компания',
  [AsideMenuItemsKeys.TENDERS]: 'Закупки',
  [AsideMenuItemsKeys.CONTRACT]: 'Договоры',
  [AsideMenuItemsKeys.DOCUMENTATION]: 'Документация',
  [AsideMenuItemsKeys.SUPPORT]: 'Справка',
};

export const asideMenuItemsRules: AsideMenuRecord<AsideMenuItemsRules[]> = {
  [AsideMenuItemsKeys.CONTRACTOR]: [AsideMenuItemsRules.Employee],
  [AsideMenuItemsKeys.ACCREDITATION]: [AsideMenuItemsRules.Employee],
  [AsideMenuItemsKeys.REVIEW]: [AsideMenuItemsRules.Employee],
  [AsideMenuItemsKeys.COMPANY]: [AsideMenuItemsRules.NotEmployee],
  [AsideMenuItemsKeys.TENDERS]: [AsideMenuItemsRules.All],
  [AsideMenuItemsKeys.CONTRACT]: [AsideMenuItemsRules.NotDemo],
  [AsideMenuItemsKeys.DOCUMENTATION]: [AsideMenuItemsRules.All],
  [AsideMenuItemsKeys.SUPPORT]: [AsideMenuItemsRules.All],
};

export const asideMenuItemsPermissions: AsideMenuRecord<TPermissions[] | null> = {
  [AsideMenuItemsKeys.CONTRACTOR]: null,
  [AsideMenuItemsKeys.ACCREDITATION]: null,
  [AsideMenuItemsKeys.REVIEW]: [PERMISSION[DEPARTMENT.SECURITY]],
  [AsideMenuItemsKeys.COMPANY]: null,
  [AsideMenuItemsKeys.TENDERS]: [NPermissions.List[NPermissions.Tender.view]],
  [AsideMenuItemsKeys.CONTRACT]: [
    NPermissions.List[NPermissions.Contracts.view],
    NPermissions.List[NPermissions.Contracts.viewOwn],
  ],
  [AsideMenuItemsKeys.DOCUMENTATION]: [NPermissions.List[NPermissions.Documents.view]],
  [AsideMenuItemsKeys.SUPPORT]: [NPermissions.List[NPermissions.Support.view]],
};
