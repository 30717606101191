import React from 'react';
import message from 'antd/lib/message';
import { AxiosError } from 'axios';
import { Text } from 'components/Typography/Text';
import { Strong } from 'components/Typography';
import { TAixosErrorResponse } from 'api/types';
import { JointContent } from './old/types.old';

const getContentFromError = (err: AxiosError<TAixosErrorResponse>) => {
  const codeStatus = err?.response?.status;

  if (typeof err?.response?.data !== 'object') {
    return (
      <>
        <Text>
          <Strong>Код ошибки</Strong>: {codeStatus}
        </Text>
        {err?.response?.data}
      </>
    );
  }

  if (Array.isArray(err?.response?.data?.error)) {
    return err?.response?.data.error.map((error: string) => (
      <div key={error}>{error}</div>
    ));
  }

  if (typeof err?.response?.data === 'object' && err?.response?.data !== null) {
    return Object.keys(err.response?.data).map((key, id) => {
      if (typeof err?.response?.data[key] === 'object') throw new Error();

      return (
        <div key={`${key}${id}`}>
          <Text strong>{key}</Text>: {err?.response?.data[key] as string}
        </div>
      );
    });
  }
};

export const requestErrorMessage = (error: AxiosError<TAixosErrorResponse>) => {
  let content: any;
  let colon = ':';
  try {
    content = getContentFromError(error);
  } catch {
    colon = '';
  }

  const config = {
    duration: 5,
    maxCount: 1,
    content: (
      <>
        <span>Произошла ошибка при обращении к серверу{colon}</span>
        {content}
      </>
    ),
    type: 'error',
  } as JointContent;

  return message.error(config);
};

export const inDevMessage = () => message.warning('Находится в разработке');

export const errorMessage = (msg: string | React.ReactNode | null = null) => {
  const config = {
    duration: 5,
    maxCount: 1,
    content: (
      <>
        <span>Произошла ошибка при обращении к серверу</span>
        {msg}
      </>
    ),
    type: 'error',
  } as JointContent;

  message.error(config);
};
