import { CURRENCY } from 'types/common';
import { PROPOSAL_VAT } from 'types/proposal';

export enum DECISION_TYPE {
  DEFAULT = 'DEFAULT',
  MANUALLY = 'MANUALLY',
  DISTRIBUTE_VOLUMES = 'DISTRIBUTE_VOLUMES',
}

export const DECISION_TYPE_TITLE: Partial<Record<DECISION_TYPE, string>> = {
  [DECISION_TYPE.MANUALLY]: 'Вручную',
  [DECISION_TYPE.DISTRIBUTE_VOLUMES]: 'Распределить объёмы',
};

// CELLS
export enum TYPE_CELL {
  DATA = 'DATA',
  INFO = 'INFO',
  DIVIDER = 'DIVIDER',
}

export enum STATUS_CELL {
  WINNER = 'WINNER',
  RESERVE = 'RESERVE',
  NO_DECISION = 'PARTICIPANT',
}

export enum COMPARING_AMOUNT {
  BIGGER = 'bigger',
  LOWER = 'lower',
  DEFAULT = 'default',
}

// OTHER
export interface IOtherRows {
  readonly titleId: string;
  valueType: VALUE_TYPE_CONDITION;
  value?: {
    text: string;
    oldText: string;
  };
  values?: {
    name: string;
    text: string;
  }[];
}
export interface IOtherProposals {
  readonly proposalId: number;
  rows: IOtherRows[];
}

export interface IOtherConditions {
  proposals: IOtherProposals[];
  titles: {
    name: string;
    readonly titleId: string;
  }[];
}

// DATA

export enum VALUE_TYPE_CONDITION {
  LIST = 'LIST',
  VALUE = 'VALUE',
  DOC = 'DOC',
}

export interface IWinnerTableServer {
  decisionType: DECISION_TYPE;
  materials: IMaterialTable[];
  proposals: IProposalTable[];
  otherConditions: IOtherConditions;
  showRowAmount: boolean;
  showRowVolume: boolean;
  differentVolume: boolean;
}

export interface IMaterialTable {
  id: number;
  name: string;
  order: number;
  volume: number;
  amount: number;
  totalAmount: number;
  unit: string;
  mainMaterials: number;
  status?: STATUS_CELL;
  typeCell: TYPE_CELL;
}

export interface IProposalTable {
  id: number;
  currency: CURRENCY;
  main?: boolean;
  price: number;
  vat: PROPOSAL_VAT;
  status: STATUS_CELL;
  proposalValues: IProposedValue[];
  hoverStatus?: STATUS_CELL;
  company: {
    id: number;
    name: string;
    city: string;
    tin: string;
  };
}

export interface IProposedValue {
  id: number;
  proposalId: number;
  materialId: number;
  amount: number;
  unit: string;
  volume: number;
  approvedVolume: number;
  status: STATUS_CELL;
  comparingAmount: COMPARING_AMOUNT;
  decision: STATUS_CELL; // ADD new field
}

export interface IAdditionalFields {
  totalAmount: number;
  totalVolume: number;
  rowStatus: STATUS_CELL;
}

export type TProposalsCell = Record<string, IProposedValue>;

export type TWinnerTableRowItem = IMaterialTable & TProposalsCell;
export type TWinnerTableData = TWinnerTableRowItem & IAdditionalFields;

// Winner Results

export interface IProposalWinnerResults {
  companyName: string;
  decision: string;
  id: number;
  main: boolean;
  currency: CURRENCY;
  proposalValues: IProposedValue[];
}
